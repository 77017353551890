/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
	useState,
	useCallback,
	ComponentType,
	createContext,
	useEffect,
	PropsWithChildren,
	useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Connection, Edge } from 'reactflow'
import { ChainsSdk, StepSdk } from '@cango-app/sdk'
import _set from 'lodash/set'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { createSelector } from 'reselect'
import { v4 } from 'uuid'
import _uniqBy from 'lodash/uniqBy'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { usePrevious } from 'src/hooks/usePrevious'

import { showSnackbar } from '../../helpers/snackbarManager'

import { ListedStep, PropogatedNode, StepCycles } from './types'

export type ChainChildProps = {
	chain: V3ClientTypes.Chain.BlueprintChain | undefined
	isLoading: boolean
	steps: ListedStep[]
	onRemoveDeletedStep: (stepId: string) => void
	onAddStep: (parentId: string, callback?: () => void) => Promise<void>
	onUpdateConnection: (data: {
		connection: Pick<Connection, 'source' | 'target'>
		method: 'add' | 'remove' | 'update'
		optionIds?: string[]
		createForEveryOption: boolean
		thread: V3BlueprintTypes.Thread | null
		databaseLogic: V3BlueprintTypes.Child['database_chain_logic']
		option_condition: V3BlueprintTypes.StepChild['option_condition']
	}) => Promise<void>
	onUpdateStep: (data: ListedStep) => void
	updateLastStepInChain: (oldStepId: string[], newStepId: string) => void
	updateStep: (
		stepId: string,
		data: StepSdk.UpdateStepRequest,
	) => Promise<V3ClientTypes.Blueprint.Step | void>
	extractChain: (data: {
		firstStepId: string
		newName: string
		onComplete: () => void
	}) => Promise<void>
	nodes: PropogatedNode[]
	nodeMap: Map<string, PropogatedNode>
	threadMap: Map<string, V3BlueprintTypes.Thread[]>
	setNodes: React.Dispatch<React.SetStateAction<PropogatedNode[]>>
	setAdjacencyList: React.Dispatch<React.SetStateAction<Map<string, Edge[]>>>
	adjacencyList: Map<string, Edge[]>
	nodeDescendantsMap: Map<string, PropogatedNode[]>
	chainIdLoading: string
	updateChain: (updates: ChainsSdk.UpdateChainRequest) => Promise<void>
	stepCycles: StepCycles
	setStepCycles: React.Dispatch<React.SetStateAction<StepCycles>>
}

export const ChainContext = createContext<ChainChildProps>({
	chain: undefined,
	isLoading: false,
	steps: [],
	onRemoveDeletedStep: () => {},
	onAddStep: async () => {},
	onUpdateConnection: async () => {},
	onUpdateStep: () => {},
	updateLastStepInChain: () => {},
	updateStep: async () => {},
	extractChain: async () => {},
	nodes: [],
	nodeMap: new Map(),
	setNodes: () => [],
	setAdjacencyList: () => new Map(),
	adjacencyList: new Map(),
	nodeDescendantsMap: new Map(),
	chainIdLoading: '',
	updateChain: async () => {},
	stepCycles: new Map(),
	setStepCycles: () => new Map(),
	threadMap: new Map(),
})

const getThreads = ({
	nodeMap,
	nodeId,
	visitedNodes = new Set(),
}: {
	nodeMap: Map<string, PropogatedNode>
	nodeId: string
	visitedNodes?: Set<string>
}): V3BlueprintTypes.Thread[] => {
	const node = nodeMap.get(nodeId)
	if (!node || visitedNodes.has(nodeId)) return []
	visitedNodes.add(nodeId)
	const threads: V3BlueprintTypes.Thread[] = []
	const directParents = node.data.parents.reduce((_parents: PropogatedNode[], _parentId) => {
		const parent = nodeMap.get(_parentId)
		if (!parent) return _parents
		if (!parent.data.descendants.some((_desc) => _desc.step === nodeId)) {
			return _parents
		}
		_parents.push(parent)
		return _parents
	}, [])

	for (const _directParent of directParents) {
		_directParent.data.descendants.forEach((_desc) => {
			if (_desc.step !== nodeId) return
			if (_desc.database_chain_logic) {
				threads.push({
					color: '#c4def6',
					prefix: _desc.database_chain_logic.column,
					_id: _desc._id,
				})
				return
			}
			if (_desc.thread?._id) {
				threads.push(_desc.thread)
				return
			}
		})
		if (_directParent.data.isLastStepInChain) {
			continue
		}
		const parentOfDirectParentThreads = getThreads({
			nodeMap,
			nodeId: _directParent.data._id,
			visitedNodes,
		})
		threads.push(...parentOfDirectParentThreads)
	}

	return _uniqBy(threads, '_id')
}

const getNodeMap = createSelector(
	(nodes: PropogatedNode[]) => nodes,
	(nodes) => {
		return new Map(nodes.map((_node) => [_node.id, _node]))
	},
)

const getThreadMap = createSelector(
	(nodes: PropogatedNode[]) => nodes,
	getNodeMap,
	(nodes, nodeMap) => {
		return new Map(nodes.map((_node) => [_node.id, getThreads({ nodeMap, nodeId: _node.id })]))
	},
)

const getNodeDescendantsMap = createSelector(
	(nodes: PropogatedNode[], adjacencyList: Map<string, Edge[]>) => ({ nodes, adjacencyList }),
	getNodeMap,
	({ nodes, adjacencyList }, nodeMap) => {
		const descendantsMap = new Map<string, PropogatedNode[]>()

		const getAdjacentNodeIds = (nodeId: string, visitedNodes = new Set<string>()) => {
			if (visitedNodes.has(nodeId)) return []
			visitedNodes.add(nodeId)
			const adjacentEdges = adjacencyList.get(nodeId)
			if (!adjacentEdges) return []
			const currentNode = nodeMap.get(nodeId) as PropogatedNode
			if (!currentNode) return []
			const adjacentNodeIds = adjacentEdges.map((_edge) => _edge.target)
			const adjacentNodes = adjacentNodeIds.map((_id) => nodeMap.get(_id) as PropogatedNode)
			const nodesWithGreaterRanks = adjacentNodes.filter(
				(_adj) => _adj?.rank && currentNode?.rank && _adj.rank > currentNode.rank,
			)
			const nodeDescendants = nodesWithGreaterRanks.map((_node) => _node.id)
			if (nodesWithGreaterRanks.length) {
				nodesWithGreaterRanks.forEach((_node) => {
					const _id = _node.id
					const adjacentNode = nodeMap.get(_id)
					if (!adjacentNode) {
						return
					}
					const childNodeIds = getAdjacentNodeIds(_id, visitedNodes)
					nodeDescendants.push(...childNodeIds)
				})
			}

			return [...new Set(nodeDescendants)]
		}

		nodes.forEach((_node) => {
			const adjacentNodeIds = getAdjacentNodeIds(_node.id)
			const adjacentNodes = adjacentNodeIds.map((_id) => nodeMap.get(_id) as PropogatedNode)
			descendantsMap.set(_node.id, adjacentNodes)
		})

		return descendantsMap
	},
)

export const ChainProvider: ComponentType<PropsWithChildren<{ chainId?: string }>> = (props) => {
	const dispatch = useDispatch()
	const [chainIdLoading, setChainIdLoading] = useState<string>('')
	const [chain, setChain] = useState<V3ClientTypes.Chain.BlueprintChain>()
	const [chainSteps, setChainSteps] = useState<ListedStep[]>([])
	const [nodes, setNodes] = useState<PropogatedNode[]>([])
	const [adjacencyList, setAdjacencyList] = useState<Map<string, Edge[]>>(new Map())
	const [stepCycles, setStepCycles] = useState<StepCycles>(new Map())
	const previousChainId = usePrevious(props.chainId)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const { nodeMap, threadMap } = useMemo(() => {
		const nodeMap = getNodeMap(nodes)
		const threadMap = getThreadMap(nodes)
		return { nodeMap, threadMap }
	}, [nodes])

	const fetchChain = useCallback(async () => {
		if (!props.chainId) return
		try {
			setChainIdLoading(props.chainId)
			const chain = await ChainsSdk.get(
				import.meta.env.VITE_API as string,
				authHeaders,
				props.chainId,
			)
			const steps = await ChainsSdk.getSteps(
				import.meta.env.VITE_API as string,
				authHeaders,
				props.chainId,
			)
			setChain(chain)
			setChainSteps(steps)
		} catch (error) {
			errorHandler({ error, dispatch })
		} finally {
			setChainIdLoading('')
		}
	}, [props.chainId, authHeaders])

	const onRemoveDeletedStep = useCallback(
		(stepId: string) => {
			setChainSteps((prev) => prev.filter((step) => step._id !== stepId))
		},
		[authHeaders, chainSteps],
	)

	const updateChain = useCallback(
		async (updates: ChainsSdk.UpdateChainRequest) => {
			if (!chain?._id) return
			try {
				const response = await ChainsSdk.update(
					import.meta.env.VITE_API as string,
					authHeaders,
					chain._id,
					updates,
				)
				setChain(response)
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Failed to update chain' })
			}
		},
		[chain],
	)

	const onAddStep = useCallback(
		async (parentId: string, callback?: () => void) => {
			if (!props.chainId) return
			try {
				const response = await StepSdk.create(import.meta.env.VITE_API as string, authHeaders, {
					chainId: props.chainId,
					parentId,
				})
				setChainSteps((prev) => [
					...prev.map((_step) => {
						if (_step._id === parentId) {
							return response.updatedTask
						}
						return _step
					}),
					response.newTask,
				])
				if (callback) {
					callback()
				}
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Failed to create step' })
			}
		},
		[authHeaders, props.chainId],
	)

	const onUpdateConnection = useCallback(
		async ({
			connection,
			method,
			thread,
			createForEveryOption,
			databaseLogic,
			option_condition,
		}: {
			connection: Pick<Connection, 'source' | 'target'>
			method: 'add' | 'remove' | 'update'
			thread: V3BlueprintTypes.Thread | null
			createForEveryOption: boolean
			databaseLogic: V3BlueprintTypes.Child['database_chain_logic']
			option_condition: V3BlueprintTypes.StepChild['option_condition']
		}) => {
			try {
				if (!connection.target || !connection.source) {
					return
				}
				const stepId = connection.source
				const step = chainSteps.find((_step) => _step._id === stepId)
				if (!step) {
					return
				}
				const descendantsCopy = step.descendants.reduce(
					(
						_descObject: {
							[stepId: string]: V3BlueprintTypes.Descendant
						},
						_desc,
					) => {
						_descObject[_desc.step] = _desc
						return _descObject
					},
					{},
				)

				if (method === 'add') {
					if (descendantsCopy[connection.target]) {
						showSnackbar('Connection already exists', { variant: 'warning' })
						return
					}
					descendantsCopy[connection.target] = {
						step: connection.target,
						database_chain_logic: null,
						createForEveryOption: false,
						thread: null,
						_id: v4(),
					}
				} else if (method === 'remove') {
					delete descendantsCopy[connection.target]
				} else if (method === 'update') {
					const target = connection.target
					if (!target) return
					descendantsCopy[target] = {
						step: target,
						database_chain_logic: databaseLogic,
						option_condition: option_condition,
						createForEveryOption: createForEveryOption,
						thread: thread ? { ...thread, color: thread.color ?? '#c4def6' } : null,
						_id: descendantsCopy[target]._id,
					}
				}
				const formattedDescendants = Object.values(descendantsCopy)

				await StepSdk.update(import.meta.env.VITE_API as string, authHeaders, stepId, {
					descendants: formattedDescendants,
				})

				setChainSteps((prev) =>
					prev.map((_step) => {
						if (_step._id === stepId) {
							_set(_step, 'descendants', formattedDescendants)
						}
						return _step
					}),
				)
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Failed to update step' })
			}
		},
		[chainSteps],
	)

	const extractChain = useCallback(
		async (data: {
			// firstStepId: string
			// newName: string
			// onComplete: () => void
		}) => {
			// if (!chain?._id) return
			// const firstStep = nodes.find((_node) => _node.id === firstStepId)?.data
			//
			// let chainStepIds =
			// 	getNodeDescendantsMap(nodes, adjacencyList)
			// 		.get(firstStepId)
			// 		?.map((_node) => _node.id) ?? []
			//
			// if (firstStep?.thread?._id) {
			// 	chainStepIds = nodes.reduce((_acc: string[], _node) => {
			// 		if (_node.data.thread?._id === firstStep.thread?._id) {
			// 			_acc.push(_node.id)
			// 		}
			// 		return _acc
			// 	}, [])
			// }
			//
			// try {
			// 	const response = await ChainsSdk.extract({
			// 		baseURL: import.meta.env.VITE_API as string,
			// 		authHeaders,
			// 		data: {
			// 			firstStepId,
			// 			name: newName,
			// 			steps: chainStepIds,
			// 			chainDefinition: chain?._id,
			// 		},
			// 	})
			// 	setChainSteps(response.steps)
			// 	onComplete()
			// } catch (error) {
			// 	errorHandler({ error, dispatch, message: 'Failed to extract chain' })
			// }
		},
		[authHeaders, chain?._id, nodes, adjacencyList],
	)

	const handleUpdateTask = useCallback(async (step: ListedStep) => {
		setChainSteps((prev) => prev.map((_step) => (_step._id === step._id ? step : _step)))
	}, [])

	const handleUpdateLastStepInChain = async (oldStepIds: string[], newStepId: string) => {
		try {
			const response = await ChainsSdk.updateLastStepInChain(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					oldStepIds,
					newStepId,
				},
			)
			setChainSteps((prev) => {
				return prev.map((_step) => {
					const stepUpdate = response.find((_updatedStep) => _updatedStep._id === _step._id)
					if (stepUpdate) {
						return stepUpdate
					}
					return _step
				})
			})
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Failed to update last step in chain' })
		}
	}

	const updateStep = useCallback(
		async (stepId: string, data: StepSdk.UpdateStepRequest) => {
			try {
				const step = await StepSdk.update(
					import.meta.env.VITE_API as string,
					authHeaders,
					stepId,
					data,
				)
				setChainSteps((prev) => {
					return prev.map((_step) => {
						if (_step._id === stepId) {
							return step
						}
						return _step
					})
				})
				return step
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update step' })
			}
		},
		[authHeaders],
	)

	useEffect(() => {
		if (props.chainId && props.chainId !== previousChainId) {
			fetchChain()
			return
		}

		if (previousChainId && !props.chainId) {
			setChain(undefined)
			setChainSteps([])
		}
	}, [previousChainId, props.chainId])

	const values = useMemo(
		(): ChainChildProps => ({
			chain,
			isLoading: !!chainIdLoading,
			chainIdLoading,
			steps: chainSteps ?? [],
			onRemoveDeletedStep,
			onAddStep,
			onUpdateConnection,
			onUpdateStep: handleUpdateTask,
			updateLastStepInChain: handleUpdateLastStepInChain,
			updateStep,
			extractChain,
			nodes,
			nodeMap,
			setNodes,
			setAdjacencyList,
			adjacencyList,
			nodeDescendantsMap: getNodeDescendantsMap(nodes, adjacencyList),
			updateChain,
			stepCycles,
			setStepCycles,
			threadMap,
		}),
		[chain, chainIdLoading, chainSteps, nodeMap, nodes, adjacencyList, stepCycles],
	)

	return <ChainContext.Provider value={values}>{props.children}</ChainContext.Provider>
}
