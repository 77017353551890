import { ComponentType, useContext, useState } from 'react'
import Stack from '@mui/material/Stack'
import { useParams } from 'react-router-dom'

import { Box, Button, Divider, LoadingDocument, Text } from 'src/components'
import { TableContext, TableProvider } from 'src/providers/table-provider'
import { ChevronDown } from 'src/assets/icons'

import { RouteId } from '../../constants/routes'

import { TableMenu } from './table-menu'
import { CoreTable } from './core-table'

const TablesContent: ComponentType<{
	isMenuCollapsed: boolean
	onMenuToggleClick: () => void
	menuWidth: number
}> = ({ isMenuCollapsed, onMenuToggleClick, menuWidth }) => {
	const { table, isLoadingTable, tableList } = useContext(TableContext)

	if (isLoadingTable) {
		return (
			<Box flex={1}>
				<LoadingDocument errorText="" returnToRoute={RouteId.Tables} docType="database" />
			</Box>
		)
	}

	if (!table?._id) {
		return (
			<Box display="flex" flexDirection="column" alignItems="center" mt={10} flex={1}>
				<Text variant="h4" color={'grey'}>
					{tableList.length > 0 ? 'Select a table' : 'Create a table to get started'}
				</Text>
			</Box>
		)
	}

	return (
		<Box
			width={`calc(100% - ${!isMenuCollapsed ? `${menuWidth + 16}px` : '16px'})`}
			height="calc(100vh - 150px)"
			pt={1}
			pl={isMenuCollapsed ? 2 : 0}
		>
			<Stack direction="row" spacing={2} mb={1}>
				<Button
					variant="text"
					size="small"
					startIcon={
						<ChevronDown style={{ transform: `rotate(${isMenuCollapsed ? '270' : '90'}deg)` }} />
					}
					sx={{ minWidth: 75 }}
					onClick={onMenuToggleClick}
				>
					{isMenuCollapsed ? 'Show ' : 'Hide '}menu
				</Button>
			</Stack>
			<CoreTable rowReordering />
		</Box>
	)
}

export const TablesContainer: ComponentType = () => {
	const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)
	const [columnWidth, setColumnWidth] = useState(350)
	const { tableId: tableIdParam } = useParams<{
		tableId?: string
	}>()

	const handleMouseMove = (e: MouseEvent) => {
		setColumnWidth(e.clientX)
	}

	const handleMouseUp = () => {
		document.removeEventListener('mousemove', handleMouseMove)
		document.removeEventListener('mouseup', handleMouseUp)
	}

	const handleMouseDown = () => {
		document.addEventListener('mousemove', handleMouseMove)
		document.addEventListener('mouseup', handleMouseUp)
	}

	return (
		<TableProvider tableId={tableIdParam}>
			<Box display="flex" mr={2} flex={1}>
				{!isMenuCollapsed && <TableMenu columnWidth={columnWidth} />}
				{!isMenuCollapsed && (
					<Divider
						orientation="vertical"
						flexItem
						sx={{ borderRightWidth: 4, mr: 2, cursor: 'col-resize' }}
						onMouseDown={handleMouseDown}
					/>
				)}
				<TablesContent
					isMenuCollapsed={isMenuCollapsed}
					onMenuToggleClick={() => {
						setIsMenuCollapsed(!isMenuCollapsed)
					}}
					menuWidth={columnWidth}
				/>
			</Box>
		</TableProvider>
	)
}

export default TablesContainer
